<template>
    <div class="video-container">
        <div class="video">
            <div class="video-content">
                <div class="video-item" >
                    <div class="video-work" v-if="Object.keys(videoData).length > 0">
                        <a :href="videoData.aweme_url" class="videoImg" target="_blank">
                            <img  :src="videoData.aweme_cover">
                        </a>
                        <div class="video-right">
                            <div class="title">
                                <span class="video-title" >{{videoData.aweme_title}}</span>
                                <i class="iconfont is-linked-goods"></i>
                            </div>
                            <div class="like">
                                <span class="span-hover"><i class="iconfont">&#xe62a;</i>{{videoData.digg_count}}</span>
                                <span class="span-hover"><i class="iconfont">&#xe6e7;</i>{{videoData.share_count}}</span>
                                <span class="span-hover"><i class="iconfont">&#xe664;</i>{{videoData.comment_count}}</span>
                            </div>
                            <div class="tag">
                                <span class="tag-title">热词：</span>
                                <div class="tag-list">
                                    <span v-for="(item,index) in videoData.hot_words" :key="index">{{item}}</span>
                                </div>
                            </div>
                            <div class="publish-time">
                                <span>发布时间：{{videoData.aweme_create_time}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-else class="video-item-null">
                        <p>该商品未关联数据</p>
                    </div>
                    <div class="handel">
                        <button type="button" @click="toPlay(videoData.aweme_url)" v-if="Object.keys(videoData).length > 0">播放视频</button>
                        <button type="button" @click="toReturn()">返回</button>
                    </div>
                </div>

            </div>
            <el-tabs v-model="activeName1" type="card" @tab-click="handleClick">
                <el-tab-pane label="视频详情" name="first" lazy><VideoDetailsModule /></el-tab-pane>
                <el-tab-pane label="视频观众分析" name="second" lazy><VideoAudienceAnalysisModule /></el-tab-pane>
                <el-tab-pane label="商品分析" name="third" lazy><VideoGoodsAnalysisModule :author-id="authorId" /></el-tab-pane>
                <el-tab-pane label="电商视频热度监控" name="fourth" lazy><VideoHeatModule /></el-tab-pane>
            </el-tabs>
        </div>
        <!--右侧帮助中心-->
        <RightHelpList></RightHelpList>
    </div>

</template>

<script>
    import VideoDetailsModule from '../../../components/ecmodule/VideoDetailsModule.vue'
    import VideoAudienceAnalysisModule from '../../../components/ecmodule/VideoAudienceAnalysisModule.vue'
    import VideoGoodsAnalysisModule from '../../../components/ecmodule/VideoGoodsAnalysisModule.vue'
    import VideoHeatModule  from '../../../components/ecmodule/VideoHeatModule.vue'
    import RightHelpList from '@/components/helpCenter/RightHelpList.vue'
    import dayjs from "dayjs";
    export default {
        name: "DsVideoDetails",
        components:{
            VideoDetailsModule,
            VideoAudienceAnalysisModule,
            VideoGoodsAnalysisModule,
            VideoHeatModule ,
            RightHelpList,
        },
        data() {
            return {
                videoDetail: {},
                videoData: {},
                activeName1:'first',
                authorId: "",
            }
        },
        mounted() {
            let name = localStorage.getItem('currentTab')
            // 判断是否存在currentTab，即tab页之前是否被点击切换到别的页面
            // 获取视频详情
            let aweme_id = this.$route.query.aweme_id;
            let activeName1 = this.$route.query.activeName;
            if(activeName1){
                this.activeName1 = activeName1
            }
            let param = {
                aweme_id:aweme_id
            };
            this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param,res => {
                if (res.code === 200) {
                    this.authorId = res.data.author.author_id
                    // this.videoData = res.data;
                    let obj = {};
                    obj['aweme_title'] = res.data.aweme.aweme_title;
                    obj['aweme_cover'] = res.data.aweme.aweme_cover;
                    obj['aweme_url'] = res.data.aweme.aweme_url;
                    obj['hot_words'] = res.data.aweme.hot_words;
                    obj['digg_count'] = res.data.aweme.digg_count;
                    obj['share_count'] = res.data.aweme.share_count;
                    obj['comment_count'] = res.data.aweme.comment_count;
                    obj['aweme_create_time'] = dayjs(res.data.aweme.aweme_create_time * 1000).format("MM/DD");
                    this.videoData = obj;
                }else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
        },
        methods: {
            //跳转播放页面
            toPlay(aweme_url) {
                window.open(aweme_url);
            },
            toReturn() {
                this.$router.go(-1);
                localStorage.setItem('activeName','third')
            },
            handleClick(tab) {
                // 点击tab后触发事件，修改显示页面，将状态保存在localStorage里面
                localStorage.setItem('currentTab', tab.name)
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-container{
        display: flex;
        justify-content: start;
        height: 100%;
        .video{
            height: 100%;
            flex: 1;
            padding-right: 20px;
            display: flex;
            flex-direction: column;
            .video-content{
                background-color: #fff;
                margin-bottom:20px;
                padding: 15px 20px;
                .video-item{
                    height: 100%;
                    border-bottom: none;
                    align-items: inherit;
                    display: flex;
                    .video-work{
                        display: flex;
                        flex: 1;
                        .videoImg{
                            width: 105px;
                            height: 130px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img{
                                max-width:100%;
                                max-height:100%;
                            }
                        }
                        .video-right{
                            margin-left: 20px;
                            .title{
                                align-items: inherit;
                                padding-bottom: 0;
                                max-width: 430px;
                                .video-title{
                                    color: #333;
                                    font-size: 16px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    white-space: inherit;
                                }
                            }
                            .like{
                                display: flex;
                                align-items: center;
                                .span-hover{
                                    display: flex;
                                    align-items: center;
                                    padding: 10px 20px 0 0;
                                    color: #999;
                                    i{
                                        font-size:18px;
                                        color: #999;
                                        padding: 0 6px 0 0;
                                    }
                                }
                            }
                            .tag{
                                display: flex;
                                margin-top: 10px;
                                .tag-title {
                                    display: inline-block;
                                    min-width: 38px;
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #999;
                                }
                                .tag-list{
                                    display: inline-block;
                                    max-height: 56px;
                                    overflow: hidden;
                                    margin-bottom: 10px;
                                    span{
                                        padding: 0 10px;
                                        background: rgba(244,244,244,1);
                                        border-radius: 12px;
                                        display: inline-flex;
                                        margin:0 10px 6px 0;
                                    }
                                }
                            }
                            .publish-time{
                                color: #999;
                            }

                        }
                    }
                    .handel{
                        display: flex;
                        flex-direction: column;
                        button{
                            width: 106px;
                            height: 32px;
                            color: #fff;
                            background:linear-gradient(85deg,rgba(255,0,0,1),rgba(255,96,96,1));
                            border-radius:2px;
                            border: none;
                            cursor: pointer;
                            margin-bottom: 10px;
                            &:hover{
                                background:linear-gradient(85deg,rgba(255,96,96,1),rgba(255,0,0,1));
                            }
                            &:focus{
                                outline: none;
                            }
                        }
                    }

                    .video-item-null{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 1;
                        width: 1%;
                        span{
                            /*min-height: 130px;*/
                            line-height: 130px;
                        }
                    }
                }
            }
            ::v-deep.el-tabs{
                box-shadow: none;
                border: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                background: transparent;
                flex: 1;
            }
            ::v-deep.el-tabs__header{
                border-bottom: none;
                margin: 0;
            }
            ::v-deep.el-tabs__content{
                flex: 1;
                background: #fff;
                padding-top: 20px;
                min-height: 668px;
            }
            ::v-deep.el-tab-pane{
                height: 100%;
            }
            ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
                background: #fff;
            }
            ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
                border-left: none;
            }
            ::v-deep.el-tabs__header .is-active {
                background: #fff;
                font-size: 16px;
                font-weight: 400;
                color: #0824ce;
                border-radius: 6px 6px 0 0;
            }
            ::v-deep.el-tabs__item{
                height: 52px;
                line-height: 52px;
            }
            ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
                border: none;
            }
            ::v-deep.el-tabs__item:hover {
                color: #0824ce;
                cursor: pointer;
            }
        }
    }
</style>