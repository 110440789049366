<template>
    <div class="goods-analysis">
        <div class="goods-trend-box">
            <div class="chart-title">
                <span>指标趋势分析</span>
            </div>
            <div class="tab-header-switch">
                <el-radio-group v-model="typeRadio" size="mini" @input="switchTab">
                    <el-radio-button :label="value.value" v-for="(value, key) in types" :key="key">{{value.label}}</el-radio-button>
                </el-radio-group>
                <el-radio-group v-model="chartRadio" size="mini" @input="switchTabTotal">
                    <el-radio-button :label="value.value" v-for="(value, key) in chartTypes" :key="key">{{value.label}}</el-radio-button>
                </el-radio-group>
            </div>
            <div class="echarts-data-picture">
                <div >
                    <div id="base-data" style="width: 100%;height: 100%;"></div>
                </div>
            </div>

        </div>
        <div class="goods-trend-box">
            <div class="chart-title">
                <span>综合转化分析</span>
                <el-tooltip effect="dark" content="IPM，即千次浏览互动量；GPM，即千次浏览成交额" placement="top-start">
                    <i class="el-icon-question"></i>
                </el-tooltip>
            </div>
            <div class="echarts-data-picture">
                <div >
                    <div id="ipmv-data" style="width: 100%;height: 100%;"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import * as echarts from "echarts";
    import {awemeDetail, comprehensiveCharts} from "@/utils/apis";
    import dayjs from "dayjs";
    export default {
        name: "VideoHeatModule",
        data() {
            return {
                types: [
                    {label: "点赞", value: 0},
                    {label: "评论", value: 1},
                    {label: "转发", value: 2},
                    {label: "收藏", value: 3},
                ],
                chartTypes: [
                    {label: "增量", value: 0},
                    {label: "总量", value: 1}
                ],
                chartRadio: 0,
                typeRadio: 0,
                baseChart: null,
                ipmvChart: null,
                awemeId: this.$route.query.aweme_id ? this.$route.query.aweme_id : 0,
                xData: [],
                yData: [
                    //增量
                    [
                        [], //点赞
                        [], //评论
                        [], //转发
                        [], //收藏
                    ],
                    //总量
                    [
                        [], //点赞
                        [], //评论
                        [], //转发
                        [], //收藏
                    ]
                ],
                ipmvXdata: [],
                ipmvData: [],
            }
        },
        mounted() {
            this.baseChart = echarts.init(document.getElementById("base-data"))
            this.ipmvChart = echarts.init(document.getElementById("ipmv-data"))
            this.manualInit()
            this.comprehensiveCharts()
            window.onresize = () => {
                // this.baseChart.resize()
                // this.ipmvChart.resize()
            };
        },
        methods: {
            switchTab() {
                this.setOption()
            },
            switchTabTotal() {
                this.setOption()
            },
            manualInit() {
                let param = {
                    aweme_id: this.awemeId,
                    type: "hour"
                }
                awemeDetail(param).then(res => {
                    if (res.code == 200) {
                        res.data.add && res.data.add.forEach((item, key) => {
                            if (key > 7) {
                                if (dayjs(item.update_time * 1000).format("HH") === "08") {
                                    let date = dayjs(item.update_time * 1000).format("MM/DD ") + "\n" + dayjs(item.update_time * 1000).format("HH:mm")
                                    this.xData.push(date)
                                } else {
                                    this.xData.push(dayjs(item.update_time * 1000).format("HH:mm"))
                                }
                                //点赞数据
                                if (item.digg_count >= 0) {
                                    this.yData[0][0].push(item.digg_count)
                                    // this.yDigList.push(item.digg_count)
                                } else {
                                    this.yData[0][0].push("-")
                                }
                                //评论数据
                                if (item.comment_count >= 0) {
                                    this.yData[0][1].push(item.comment_count)
                                } else {
                                    this.yData[0][1].push("-")
                                }
                                //转发数据
                                if (item.share_count < 0) {
                                    this.yData[0][2].push("-")
                                } else {
                                    this.yData[0][2].push(item.share_count)
                                }
                                //收藏数据
                                if (item.collect_count < 0) {
                                    this.yData[0][3].push("-")
                                } else {
                                    this.yData[0][3].push(item.collect_count)
                                }
                            }
                        })
                        res.data.total && res.data.total.forEach((item, key) => {
                            if (key > 7) {
                                //点赞数据
                                if (item.digg_count >= 0) {
                                    this.yData[1][0].push(item.digg_count)
                                    // this.yDigList.push(item.digg_count)
                                } else {
                                    this.yData[1][0].push("-")
                                }
                                //评论数据
                                if (item.comment_count >= 0) {
                                    this.yData[1][1].push(item.comment_count)
                                } else {
                                    this.yData[1][1].push("-")
                                }
                                //转发数据
                                if (item.share_count < 0) {
                                    this.yData[1][2].push("-")
                                } else {
                                    this.yData[1][2].push(item.share_count)
                                }
                                //收藏数据
                                if (item.collect_count < 0) {
                                    this.yData[1][3].push("-")
                                } else {
                                    this.yData[1][3].push(item.collect_count)
                                }
                            }
                        })
                        this.setOption()
                    }
                }).catch(err => {console.log("err", err)})
            },
            comprehensiveCharts() {
                let param = {
                    aweme_id: this.awemeId,
                    type: "hour"
                }
                comprehensiveCharts(param).then(res => {
                    if (res.code === 200) {
                        res.data.ipmv && res.data.ipmv.forEach((item, key) => {
                            if (dayjs(item.time_node * 1000).format("HH") === "08") {
                                let date = dayjs(item.time_node * 1000).format("MM/DD ") + "\n" + dayjs(item.time_node * 1000).format("HH:mm")
                                this.ipmvXdata.push(date)
                            } else {
                                this.ipmvXdata.push(dayjs(item.time_node * 1000).format("HH:mm"))
                            }
                            if (item.value < 0) {
                                this.ipmvData.push("-")
                            } else {
                                this.ipmvData.push(item.value)
                            }
                            this.setIpmvOptions()
                        })
                    }
                }).catch(err => {
                    console.log("err", err)
                })
            },
            setOption() {
                let y_data = []
                let title = ""
                switch (this.typeRadio) {
                    case 1:
                        title = "点赞"
                        break;
                    case 2:
                        title = "评论"
                        break;
                    case 3:
                        title =  "转发"
                        break;
                    case 4:
                        title = "收藏"
                        break;
                }

                y_data = this.yData[this.chartRadio][this.typeRadio]
                let option;
                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            label: {
                                color: "#000"
                            }
                        },
                        formatter: (params) => {
                            return `${params[0].name}
                                <div style="display: flex; justify-content: flex-start;align-items: center">
                                <i style="background: #15CC96;width:10px;height: 10px;display: block;margin-right: 5px;border-radius: 5px;"></i>
                                   &nbsp;&nbsp;${params[0].value}</div>`;
                        }
                    },
                    title: {
                        text: title,
                        textStyle: {
                            fontSize:  14,
                            fontWeight: 400,
                            color: "#606266",
                        },
                        top: "5%",
                        left: '5%',
                    },
                    xAxis: {
                        type: 'category',
                        data: this.xData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: y_data,
                            type: 'line',
                            symbol: "none",
                            lineStyle: {
                                color: "#15CC96",
                            },
                        }
                    ]
                };
                option && this.baseChart.setOption(option)
            },
            setIpmvOptions() {
                let y_data;
                y_data = this.ipmvData
                let option;
                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            label: {
                                color: "#000"
                            }
                        },
                        formatter: (params) => {
                            return `${params[0].name}
                                <div style="display: flex; justify-content: flex-start;align-items: center">
                                <i style="background: #FF7754;width:10px;height: 10px;display: block;margin-right: 5px;border-radius: 5px;"></i>
                                   &nbsp;&nbsp;${params[0].value}</div>`;
                        }
                    },
                    title: {
                        text: "IPM",
                        textStyle: {
                            fontSize:  14,
                            fontWeight: 400,
                            color: "#606266",
                        },
                        top: "5%",
                        left: '5%',
                    },
                    xAxis: {
                        type: 'category',
                        data: this.ipmvXdata
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [
                        {
                            data: y_data,
                            type: 'line',
                            symbol: "none",
                            lineStyle: {
                                color: "#FF7754",
                            },
                        }
                    ]
                };
                option && this.ipmvChart.setOption(option)

            }
        }
    }
</script>

<style scoped lang="scss">
    .goods-analysis {
        font-size: 14px;
        .goods-trend-box {
            .chart-title {
                height: 48px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
              span {
                margin-right: 5px;
              }
            }
            .tab-header-switch {
                box-sizing: border-box;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .echarts-data-picture {
                div {
                  width: 100%;
                  height: 370px;
                }

            }
        }

    }
</style>