<template>
    <div class="fan-analysis">
        <div class="analysis-left">
            <div class="gender-distribution">
                <span class="title">性别分布</span>
                <div class="distribution-chart">
                    <div class="chart-icon">
                        <i class="iconfont">&#xe6a6;</i>
                        <i class="iconfont">&#xe6a8;</i>
                    </div>
                    <div class="chart-content">
                        <div class="man" :style="{width: man+'%'}"></div>
                        <div class="woman"></div>
                    </div>
                    <div class="legend">
                        <div class="legend-man">
                            <span>男</span>
                            <span>{{man}}%</span>
                        </div>
                        <div class="legend-woman">
                            <span>女</span>
                            <span>{{woman}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="age-distribution">
                <span class="title">年龄分布</span>
                <div id="videoAgeDistribution" style="width: 100%;height: 275px; position: relative"></div>
            </div>
        </div>
        <div class="analysis-right">
            <div class="tab-area">
                <span class="tab-area-title">地区分布</span>
                <span>
                    <el-radio-group v-model="radio" size="small" @change="getListByCh">
                        <el-radio-button label="0">省份</el-radio-button>
                        <el-radio-button label="1">城市</el-radio-button>
                    </el-radio-group>
                </span>
            </div>
            <div class="area-distribution">
                <div class="title">
                    <span>名称</span>
                    <span>占比</span>
                </div>
                <div class="content">
                    <div v-if="areaData.length > 0">
                        <div class="area-item" v-for="item in areaData">
                            <span class="area-name">{{item.areaName}}</span>
                            <el-progress :percentage="item.percentage" :show-text="false" :stroke-width="12"></el-progress>
                            <span class="percentage">{{item.value}}%</span>
                        </div>
                    </div>
                    <div v-else class="null-data-area">暂无数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {viewserAnalysis} from "@/utils/apis";

    export default {
        name: "VideoAudienceAnalysisModule",
        data() {
            return {
                audience: 0, //观众人员总数
                man: 0, //男生的百分比数字
                woman: 0, //女生的百分比数字
                age: [], //年龄分布列表
                areaSummary: {}, //地区分数数据
                areaData: [],//地区分布列表
                radio: 0
            };
        },
        mounted() {
            // 获取视频详情
            let aweme_id = this.$route.query.aweme_id;
            // 视频观众分析
            let param = {
                aweme_id: aweme_id
            };
            viewserAnalysis(aweme_id).then(res => {
                if (res.code === 200) {
                    let peopleTotal = 0;
                    this.genderSummary(res.data.gender_summary)
                    this.age = res.data.age_summary
                    this.videoAgeDistributionFun(this.age)
                    this.areaSummary.city = res.data.city_summary
                    this.areaSummary.province = res.data.province_summary
                    this.getListByCh()
                }
            }).catch(err => {
                console.log("err", err)
            })
            // this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param, res => {
            //     if(res.code === 200){
            //         let video_fansData = res.data.video_fansData.data;
            //         // 性别分布
            //         this.genderSummary(video_fansData.gender_summary);
            //         // 年龄分布
            //         this.age = video_fansData.age_summary;
            //         this.videoAgeDistributionFun(video_fansData.age_summary);
            //         // 地区分布
            //         // this.areaSummary(video_fansData.city_summary);
            //         //  城市分布
            //         this.areaSummary["city"] = video_fansData.city_summary;
            //         //  省份分布
            //         this.areaSummary["province"] = video_fansData.province_summary;
            //         this.areaSummaryFun(this.areaSummary.province);
            //     } else {
            //         this.$message({
            //             type: "error",
            //             message: res.message,
            //             duration: 1000
            //         });
            //         this.videoAgeDistributionFun([]);
            //     }
            //  },(err) => {
            //     this.videoAgeDistributionFun([]);
            //     console.log(err)
            // });
            // 年龄
            window.onresize = () => {
                this.videoAgeDistributionFun(this.age);
            };
        },
        methods: {
            // 性别数据分析函数
            genderSummary(gender_summary) {
                // 性别数据
                for (let i in gender_summary) {
                    if (i < 2) {
                        this.audience += gender_summary[i].count;
                    }

                }
                if(this.audience === 0){
                    this.man = this.woman = 0;
                }else {
                    this.man = ((gender_summary[0].count / this.audience) * 100).toFixed(2);
                    this.woman = ((gender_summary[1].count / this.audience) * 100).toFixed(2);
                }
            },
            // 年龄分布柱状图
            videoAgeDistributionFun(age_summary) {
                let ageArray = [],
                    dataArray = [];
                for (let i in age_summary) {
                    ageArray.push(age_summary[i].keyword);
                    dataArray.push(age_summary[i].count);
                }
                let videoAgeDistribution = this.$echarts.init(document.getElementById("videoAgeDistribution"));
                videoAgeDistribution.setOption({
                    color: {
                        type: "linear",
                        x: 0.5,
                        y: 0.5,
                        r: 0.5,
                        colorStops: [
                            {
                                offset: 1,
                                color: "#25C495" // 0% 处的颜色
                            },
                            {
                                offset: 0,
                                color: "#039167" // 100% 处的颜色
                            }
                        ]
                    },
                    grid: {
                        left: "3%",
                        right: "4%",
                        bottom: "3%",
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: "category",
                            data: ageArray,
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#ccc"
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: "value",
                            axisLine: {
                                //y轴
                                show: false
                            },
                            axisTick: {
                                //y轴刻度线
                                show: false
                            },
                            splitLine: {
                                //网格线
                                show: false
                            },
                            axisLabel: {
                                formatter: function () {
                                    return "";
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: "",
                            type: "bar",
                            barWidth: "30%",
                            itemStyle: {
                                //上方显示数值
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: "top", //在上方显示
                                        textStyle: {
                                            //数值样式
                                            color: "#ccc",
                                            fontSize: 16
                                        }
                                    }
                                }
                            },
                            data: dataArray
                        }
                    ]
              },true);
                let isZero = true;//数据是否为0
                for (let i in age_summary){
                    if(age_summary[i].count > 0){
                        isZero = false;
                        break;
                    }
                }
                if(isZero){
                    let div1 = document.createElement("div");
                    div1.innerHTML = "暂无数据";
                    div1.style.color = "#666";
                    div1.style.position = "absolute";
                    div1.style.top = "100px";
                    div1.style.left = "50%";
                    document.querySelector("#videoAgeDistribution").appendChild(div1)
                }
            },
            // 地区分布
            areaSummaryFun(data) {
                // 地区分布
                if(data.length > 0){
                    this.areaData = [];
                    for (let i = 0; i < data.length; i++) {
                        let obj = {
                            areaName: data[i].keyword,
                            value: Number((data[i].count / this.audience * 100).toFixed(2)),
                            percentage: Number((data[i].count / data[0].count * 100).toFixed(2))
                        };
                        this.areaData.push(obj);
                    }
                } else {
                    this.areaData = [];
                }
            },
            //省份/城市
            getListByCh() {
                if (this.radio == 0) {
                    this.areaSummaryFun(this.areaSummary.province);
                } else {
                    this.areaSummaryFun(this.areaSummary.city);

                }
            },
        }
    };
</script>

<style scoped lang="scss">
    .fan-analysis {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding-bottom: 20px;
        .analysis-left {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 1px solid #eaeaea;
            padding: 0 20px;
            .gender-distribution {
                display: flex;
                flex-direction: column;
                margin-bottom: 50px;
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
                .distribution-chart {
                    .chart-icon {
                        display: flex;
                        justify-content: space-between;
                        color: #34aa7f;
                        margin-top: 10px;
                        & i {
                            font-size: 24px;
                        }
                    }
                    .chart-content {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        height: 20px;
                        margin-top: 10px;
                        .man {
                            background-color: #34aa7f;
                        }
                        .woman {
                            flex: 1;
                            background-color: #46dbab;
                        }
                    }
                    .legend {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;

                        .legend-man {
                            display: flex;
                            flex-direction: column;
                        }

                        .legend-woman {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }

            .age-distribution {
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
            }
        }
       .analysis-right {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            padding: 0 20px;
            .tab-area{
                display: flex;
                justify-content: space-between;
                align-items: center;
                ::v-deep.el-radio-button__orig-radio:checked+.el-radio-button__inner{
                    color: #FFF;
                    background-color: #2338E6;
                    border-color: #2338E6;
                    box-shadow: -1px 0 0 0 #2338E6;
                }
            }
            .title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-right: 5px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
            }
            .content {
                flex: 1;
                overflow: auto;
                padding-right: 5px;
            }
            .area-distribution {
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);
                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    .area-name {
                        width: 50px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    ::v-deep .el-progress {
                        width: 80%;
                        padding: 0 10px;
                    }
                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                 }
             }
            }
       .null-data-area{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 200px;
        }
    }
</style>