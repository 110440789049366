<template>
    <!--视频详情-->
    <div class="video-detail">
        <div class="video-chart">
            <div class="header-info">
                <div class="avatar">
                    <img :src="videoDetail.aweme_cover" alt="" >
                    <div class="info">
                        <span class="video-title">{{videoDetail.aweme_title}}</span>
                        <span class="fans">{{formatNumberValue(videoDetail.follower_count)}}</span>
                    </div>
                </div>
                <div class="video-info">
                    <ul class="like-item">
                        <li>
                            <span>总点赞</span>
                            <span>{{formatNumberValue(videoDetail.digg_count)}}</span>
                        </li>
                        <li>
                            <span>总评论</span>
                            <span >{{formatNumberValue(videoDetail.comment_count)}}</span>
                        </li>
                        <li>
                            <span>总转发</span>
                            <span>{{formatNumberValue(videoDetail.share_count)}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
<!--        <div class="video-comment">-->
<!--            <span class="title">热门评论<span class="num">（{{commentList.length}}）</span></span>-->
<!--                <ul class="comment-ul" v-if="commentList.length>0">-->
<!--                    <li v-for="(item,index) in commentList">-->
<!--                        <div class="content">-->
<!--                            <span class="text">{{item.text}}</span>-->
<!--                            <div class="comment-text">-->
<!--                                <div class="text-left">-->
<!--                                    <span class="time">{{item.create_time}}</span>-->
<!--                                </div>-->
<!--                                <div class="like-num">-->
<!--                                    <i class="iconfont">&#xe85c;</i>-->
<!--                                    <span class="num">{{item.digg_count}}</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <ul v-else class="comment-ul-null">-->
<!--                    <p>该商品未关联数据</p>-->
<!--                </ul>-->
<!--        </div>-->
        <div class="video-comment">
            <div class="comment-title">
                <span class="title">热门评论</span><span>（评论数量：{{commentCount}}，<a :href="awemeUrl" target="_blank">查询详情</a>）</span>
            </div>
            <div class="comment-echarts">
                <div id="commentcharts" :style="{width: '100%', height: '442px'}"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";

    export default {
        name: "VideoDetailModule",
        data() {
            return{
                commentList:[],//
                videoDetail:{},
                awemeUrl: "",
                commentCount: 0,
                echartsIns: null,
                xData: [],
                yData: [],
            }
        },
        mounted() {
            window.onresize = () => {
                this.echartsIns.resize()
            }
            //获取echarts实例
            this.echartsIns = echarts.init(document.getElementById("commentcharts"))
            // 获取视频详情
            let aweme_id = this.$route.query.aweme_id;
            let param = {
                aweme_id:aweme_id
            };
            this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param,res => {
                if (res.code === 200) {
                    let obj = {
                        "aweme_cover":res.data.aweme.aweme_cover
                    };
                    obj['aweme_title'] = res.data.aweme.aweme_title;
                    obj['follower_count'] = res.data.author.follower_count;
                    obj['digg_count'] = res.data.aweme.digg_count;
                    obj['share_count'] = res.data.aweme.share_count;
                    obj['comment_count'] = res.data.aweme.comment_count;
                    this.videoDetail = {...obj};
                    this.awemeUrl = res.data.aweme.aweme_url
                    this.commentCount = (res.data.aweme.comment_count / 10000) > 1 ? (res.data.aweme.comment_count / 10000).toFixed(2) + "w" : res.data.aweme.comment_count
                    // this.commentList = res.data.video_comments;
                    this.xData = res.data.aweme.hot_words
                    let total = 0

                    res.data.aweme.hot_words_info && res.data.aweme.hot_words_info.forEach((item) => {
                        total += item.count
                    })
                    let list = []
                    res.data.aweme.hot_words_info && res.data.aweme.hot_words_info.forEach((item) => {
                        list.push((item.count * 100 / total).toFixed(2))
                    })
                    this.yData = list
                    this.setOptions()

                }else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 1000
                    });
                }
            });
        },
        methods: {
            formatNumberValue(value) {
                if (Number(value) > 10000) {
                    return (value/10000).toFixed(2) + "w"
                }
                return value
            },
            setOptions() {
                let options = {
                    title: {
                        text: "评论热门Top10",
                        textStyle: {
                            color: "#666",
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.xData,
                            axisTick: {
                                alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value} %'
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            barWidth: '60%',
                            data: this.yData,
                            itemStyle: {
                                color: "#36c39e"
                            }
                        }
                    ]
                };
                this.echartsIns.setOption(options)

            }
        }
    }
</script>

<style scoped lang="scss">
.video-detail{
    display: flex;
    flex-direction: column;
    padding: 0 25px 25px;
    .video-chart{
        height: 100%;
        overflow: auto;
        flex: 1;
        padding-right: 10px;
        .header-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .avatar {
                display: flex;
                justify-content: start;
                img {
                    width: 40px;
                    height: 40px;
                    margin-right: 20px;
                    border-radius: 50%;
                }
                .info {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51,51,51,1);
                    .text{
                        color: #333;
                        font-size:14px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        white-space: inherit;
                        line-height: 1.5;
                    }
                }
            }
            .video-info{
                .like-item {
                    list-style-type: none;
                    display: flex;
                    justify-content: start;
                    padding: 0;
                    margin: 0;
                    li {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 0 20px;
                        border-right: 1px solid #EAEAEA;
                        &:last-child {
                            border-right: none;
                        }
                        span{
                            line-height:1.5;
                        }

                    }
                }
            }
        }
    }
    .video-comment {
        display: flex;
        flex-direction: column;
        .comment-echarts {
            margin-top: 30px;
        }
        .title {
            font-size:14px;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            padding: 20px 0;
            .num {
                color: #aaaaaf;
            }
        }
        .comment-ul{
            flex: 1;
            padding: 0 8px 0 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                display: flex;
                justify-content: start;
                margin-bottom: 15px;
                margin-left: 10px;
                .content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height:2;
                    .comment-text {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid #EAEAEA;
                        padding-bottom: 10px;
                        .like-num {
                            display: flex;
                            align-items: center;
                            i {
                                color: #999;
                                margin-right: 5px;
                            }
                        }

                    }
                    .time{
                        color: #999;
                        font-size:14px;
                    }
                }
            }
        }
    }
    /*暂无数据*/
    .comment-ul-null{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 418px;
    }
    .video-chart-null{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>